import React, { useEffect } from 'react';
import './WelcomeScreen.css';
import { Routes, Route } from "react-router-dom";
import Slider from './Slider/Slider';
import background1 from '../../../Media/WelcomeBackground1.png';
import background2 from '../../../Media/WelcomeBackground2.png';
import background3 from '../../../Media/WelcomeBackground3.png';

const WelcomeScreen = ({ onClose }) => {

  useEffect(() => {
    function handleResize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
  },[])

  const content = [
    {
      title: 'Мы рады приветствовать тебя на платформе проекта «Почта Доверия»',
      text: ' Здесь ты можешь задать вопрос, который тебя волнует или ознакомиться с часто задаваемыми вопросами и информацией по тегам',
      backgroundImage: background1,
    },
    {
      title: '',
      text: 'У каждого из нас бывают моменты, когда особенно хочется поделиться своими переживаниями, проблемами, получить консультацию, помощь или совет.',
      backgroundImage: background2,
    },
    {
      title: '',
      text: 'Благодаря «Почте доверия» ты можешь поделиться своими тревогами, переживаниями, страхами и сомнениями. Ты можешь рассказать о том, что волнует тебя и твоих близких, что требует участия, помощи и поддержки. Мы готовы ответить на твои вопросы и помочь найти решение.',
      backgroundImage: background3,
    },
  ]

  return (
    <>
      <>
      <Routes>
        <Route path="/" element={
          <section className='welcome'>
            <Slider content={content} onClose={() => onClose()} />
          </section>
        } />
      </Routes>
    </>
    </>
  )
}
export default WelcomeScreen
