import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './PersonalCode.css'
import Button from '../Button/Button';



const PersonalCode = ({ personalCode }) => {
  const [isCopiedMsg, setIsCopiedMsg] = useState(undefined)
  const navigate = useNavigate();

  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(personalCode);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }


  return (
    <>
      <div className='PersonalCode__container'>
        <p className=''>Чтобы прочитать ответ на свой вопрос:</p>
        <ul style={{marginBottom: 20}}>
          <li className='PersonalCode__item'>сохрани свой уникальный код</li>
          <li className='PersonalCode__item'>вставь его в блокнот или заметки.</li>
          <div style={{marginTop: 32, marginBottom: 20}} className='PersonalCode__code'>{personalCode}</div>
          {isCopiedMsg &&
            <div className='PersonalCode__msg'>{isCopiedMsg}</div>}

          {/* <li className='PersonalCode__item'>введи его на главной странице в поле «ввести код» и получи ответ.</li> */}
          {isCopiedMsg && <p>Введи его на главной странице в поле «ввести код» и получи ответ.</p>}
        </ul>
        {!isCopiedMsg ?
          <Button backgroundColor={'#AEC905'}
            buttonText={'Скопировать'}
            onClickAction={() => { copyToClipboard(personalCode); setIsCopiedMsg('Код скопирован'); }} />
          :
          <Button backgroundColor={'#AEC905'}
            buttonText={'На главную'}
            onClickAction={() => { navigate('/', { replace: true }) }} />
        }
        {/* <p style={{ paddingTop: '10px' }}>Ответ будет готов через 48 часов.</p> */}
      </div>
      {isCopiedMsg && <p>Мы постараемся ответить на твой вопрос максимально быстро.  Иногда для этого может потребоваться чуть больше времени. Но ты точно получишь свой ответ в течение 5 дней.</p>}
      {/* <p className=''>Если время ожидания ответа для тебя кажется слишком долгим, то
        можно позвонить по телефону</p>
      <a href={`tel: 8-800-2000-122`}>
        <span className='PersonalCode__tel'>8-800-2000-122</span></a>
      <p style={{ paddingTop: '15px' }}>Это служба доверия, они уже очень давно работают с подростками,
        которые сталкиваются со сложными жизненными ситуациями.
      </p> */}
    </>
  )
}
export default PersonalCode
