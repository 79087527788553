import React, { useState } from 'react'
import './EnterNameBlock.css'
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
import ClientInput from './ClientInput';


const EnterNameBlock = ({ text, buttonText, placeholder, linkTo, setCurrentUser, getAnswer, backgroundColor, errorMsg, setErrorMsg, iconClass }) => {
  const [uniqID, setUniqID] = useState('')

  const handleChange = (e) => {
    if (setCurrentUser) {
      setCurrentUser({ UserName: e.target.value })
    } else if (getAnswer) {
      setUniqID(e.target.value)
      // if (e.target.value < 12){
      //   setErrorMsg()
      // }
    }
  }
  const handleClick = () => {
    if (getAnswer) {
      getAnswer(uniqID)
    }
  }
  return (
    <>
      <p className={`enterNameBlock__text`}>{text}</p>
      <form className='inputButton_form'>
        <ClientInput onChange={handleChange} placeholder={placeholder} iconClass={iconClass} />
          {errorMsg &&
          <span className={`enterNameBlock__error`}>{errorMsg}</span>}
        <Button buttonText={buttonText} backgroundColor={backgroundColor} linkTo={linkTo} onClickAction={handleClick} />
      </form>
    </>
  )
}
export default EnterNameBlock
