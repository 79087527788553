import React, { useEffect, useState } from 'react'
import './QuestionForm.css'
import Button from '../Button/Button';
import AddTagPage from '../Tags/AddTagPage';
import { useNavigate } from  'react-router-dom'
import { useCookies } from 'react-cookie';


const QuestionForm = ({ icon, setCurrentUser, currentUser, createUser }) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['school'])

  const maxNum = 250
  const [currentNum, setCurrentNum] = useState(0)
  const [choseTags, setChoseTags] = useState(false);
  const [chosenTags, setChosenTags] = useState([]);

  // useEffect(() => {
  // }, [])

  // useEffect(() => {
  //   console.log("Current user was changed", currentUser);
  // }, [currentUser])

  useEffect(() => {
    // console.log("CREATE QUESTION chosenTags changed", chosenTags);
    setQuestion();
  }, [chosenTags])

  const counter = (e) => {
    setCurrentNum(e.target.value.length)
  }
  const setQuestion = (e) => {
    // console.log("!!!ABRA KADABRA!!!")
    if (e){
      setCurrentUser({ ...currentUser, Question: e.target.value, tags: chosenTags })
    } else {
      // console.log('CREATE QUESTION  Set current tags ', chosenTags);
      setCurrentUser({ ...currentUser, tags: chosenTags })
    }
  }
  const handleClick = () => {
    // console.log("CREATE QUESTION Ready button was clicked", currentUser)

    const school = cookies.school;
    // console.log("THIS IS SCHOOL", school);

    createUser(school ? {...currentUser, school} : currentUser);
    navigate('/getCode',{replace: true});
  }
  return (
    <>
    {!choseTags ?
      <section className='questionForm'>
        <div className='questionForm__textarea-container'>
          <textarea type="text" name="question" placeholder='Напиши свой вопрос'
            className={`questionForm__input ${icon}`} autoComplete="off" minLength={8}
            maxLength={250} onChange={(e) => { counter(e); setQuestion(e) }} />
          <span className='questionForm__letter-counter'>{currentNum}/{maxNum} </span>
        </div>
        <Button backgroundColor={'#51C0E2'} buttonText={'Далее'} onClickAction={() => setChoseTags(true)} />
      </section> : 
      <AddTagPage setTags={setChosenTags} readyClick={handleClick}/>
      }
    </>
  )
}
export default QuestionForm
