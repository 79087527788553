import React from "react";
import Slider from "react-slick";
import logo from '../../../../Media/LogoWhite.svg';


function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className={'slider__btn-next'} onClick={onClick}>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={'slider__btn-prev'} onClick={onClick}>
    </div>
  );
}
export default function SimpleSlider({ content, onClose }) {

  var settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    dotsClass: "slick-dots-new",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };
  return (
    <Slider {...settings}>
      {content.map((i, id) =>
        <div className='welcome__container' key={id}>
          <div className="sliderImageHolder" style={{width: '100vw', backgroundImage: `url(${i.backgroundImage})`, backgroundSize: 'cover'}} key={id}></div>
          <div className="sliderImageShading"></div>
          {/* <img src={i.backgroundImage} alt='' key={id} className='welcome__image'></img> */}
          <div className='welcome__content-container'>
            <img src={logo} alt='logo'></img>
            <h3 className='welcome__title'>{i.title}</h3>
            <p className='welcome__text'>{i.text}</p>
            <div className='welcome__button' onClick={() => onClose(false)}>Задать вопрос</div>
          </div>
        </div>

      )}
    </Slider>
  );
}
