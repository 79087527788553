import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import './Login.css'
import EnterNameBlock from './EnterNameBlock/EnterNameBlock'

const Login = ({ setCurrentUser, getAnswer, errorMsg, setErrorMsg }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies(['school'])
  
  useEffect(() => {
    const school = searchParams.get('from');
    if (school){
      fetch(`/backend/api/schools/${school}`).then(response => response.json().then(data => {
        console.log("SCHOOL DATA", data);
        const uni_school = data.data;
        if (uni_school && !data.error){
          // console.log("setting cookie school", uni_school.id);
          setCookie('school', uni_school.id);
        }
      }))
    }
  }, [])

  return (
    <>
      <section className='login'>
        <p>Если хочешь пообщаться вживую, кликай по&nbsp;меню в правом верхнем углу.<br/>Если ты нам доверяешь, давай познакомимся.</p>
        <EnterNameBlock buttonText={'Задать свой вопрос'}
          text={'В этом поле ты можешь написать своё имя или придумать никнейм.'}
          placeholder={'Введи имя или никнейм'}
          type={'name'}
          linkTo={'/ask'}
          setCurrentUser={setCurrentUser}
          backgroundColor={'#51C0E2'} 
          iconClass={'input_name'}
          />

        <EnterNameBlock buttonText={'Получить ответ'}
          text={'Если ты уже задал вопрос и ждешь ответ, то вводи свой уникальный код в это поле.'}
          placeholder={'Введите уникальный код'}
          getAnswer={getAnswer}
          backgroundColor={'#AEC905'}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          iconClass={'input_code'}
          />
      </section>
    </>
  )
}
export default Login
